export enum DurationUnitType {
  DAY = 'day',
  HOUR = 'hour',
  MILLISECOND = 'millisecond',
  MINUTE = 'minute',
  MONTH = 'month',
  SECOND = 'second',
  WEEK = 'week',
  YEAR = 'year',
}

const MILLISECONDS_IN_SECOND = 1000;

const SECONDS_IN_MILLISECOND = 1 / MILLISECONDS_IN_SECOND;
const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * 60;
const SECONDS_IN_DAY = SECONDS_IN_HOUR * 24;
const SECONDS_IN_MONTH = SECONDS_IN_DAY * 30;
const SECONDS_IN_WEEK = SECONDS_IN_DAY * 7;
const SECONDS_IN_YEAR = SECONDS_IN_DAY * 365;

export function formatSecondsDuration(seconds: number): string {
  let restOfSeconds = Math.round(seconds);

  const days = Math.floor(restOfSeconds / SECONDS_IN_DAY);
  restOfSeconds = restOfSeconds % SECONDS_IN_DAY;

  const hours = Math.floor(restOfSeconds / SECONDS_IN_HOUR);
  restOfSeconds = restOfSeconds % SECONDS_IN_HOUR;

  const minutes = Math.floor(restOfSeconds / SECONDS_IN_MINUTE);
  restOfSeconds = restOfSeconds % SECONDS_IN_MINUTE;

  const segments = [
    [days, 'd.'],
    [hours, 'h.'],
    [minutes, 'm.'],
    [restOfSeconds, 's.'],
  ] as const;

  return segments
    .filter(([value, suffix]) => value > 0 || suffix === 's.')
    .map((segment) => segment.join(''))
    .join(' ');
}

const DURATION_TO_SECONDS_MULTIPLIERS: Record<DurationUnitType, number> = {
  [DurationUnitType.DAY]: SECONDS_IN_DAY,
  [DurationUnitType.HOUR]: SECONDS_IN_HOUR,
  [DurationUnitType.MILLISECOND]: SECONDS_IN_MILLISECOND,
  [DurationUnitType.MINUTE]: SECONDS_IN_MINUTE,
  [DurationUnitType.MONTH]: SECONDS_IN_MONTH,
  [DurationUnitType.SECOND]: 1,
  [DurationUnitType.WEEK]: SECONDS_IN_WEEK,
  [DurationUnitType.YEAR]: SECONDS_IN_YEAR,
};

export function getDurationInSeconds(time: number, unit: DurationUnitType = DurationUnitType.SECOND): number {
  return DURATION_TO_SECONDS_MULTIPLIERS[unit] * time;
}

export function getDurationInMilliseconds(time: number, unit: DurationUnitType = DurationUnitType.SECOND) {
  if (unit === DurationUnitType.MILLISECOND) {
    return time;
  }

  return getDurationInSeconds(time, unit) * MILLISECONDS_IN_SECOND;
}
