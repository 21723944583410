import type { VueQueryPluginOptions } from '@tanstack/vue-query';

import { VueQueryPlugin } from '@tanstack/vue-query';

import { DurationUnitType, getDurationInMilliseconds } from '../date-time/duration';

export default function createTanstackQuery(): [typeof VueQueryPlugin, VueQueryPluginOptions] {
  const options: VueQueryPluginOptions = {
    queryClientConfig: {
      defaultOptions: {
        queries: {
          gcTime: getDurationInMilliseconds(30, DurationUnitType.MINUTE),
          refetchOnWindowFocus: false,
          retry: false,
        },
      },
    },
  };

  return [VueQueryPlugin, options];
}
