import { defineStore } from 'pinia';

import { z } from 'zod';

import { DurationUnitType, getDurationInMilliseconds } from '../../../lib/date-time/duration';
import DefaultLogger from '../../../lib/debug/logger/DefaultLogger';
import usePersistedQuery from '../../../lib/fetch/usePersistedQuery';
import ApiResultStatus from '../../../shared/api/api-result/ApiResultStatus';
import useMonitorObserverStore from '../../monitor-observer/useMonitorObserverStore';
import checkForUpdates from './api/checkForUpdates';

export default defineStore('updateAvailability', () => {
  const monitorObserverStore = useMonitorObserverStore();

  const {
    fetchingError: checkingForUpdatesError,
    isFetching: isCheckingForUpdates,
    refetch,
    value: isUpdateAvailable,
  } = usePersistedQuery({
    getInitialValue: () => false,
    parseValue: (value) => z.boolean().parse(value),
    queryFn: async () => {
      const result = await checkForUpdates();

      monitorObserverStore.setIsObserverEnabled(result.status === ApiResultStatus.OK);

      if (result.status === ApiResultStatus.ERROR) {
        DefaultLogger.writeError(result.errorMessage);
        throw new Error(result.errorMessage);
      }

      return result.isUpdateAvailable;
    },
    queryKey: 'updateAvailability',
    staleTime: getDurationInMilliseconds(1, DurationUnitType.HOUR),
  });

  const recheckForUpdates = async ({
    onChecked,
    onError,
  }: {
    onChecked?: (isUpdateAvailable: boolean) => void;
    onError?: (errorMessage: string) => void;
  } = {}) => {
    await refetch();

    if (checkingForUpdatesError.value !== null) {
      onError?.(checkingForUpdatesError.value);
      return;
    }

    onChecked?.(isUpdateAvailable.value);
  };

  return {
    isCheckingForUpdates,
    isUpdateAvailable,
    recheckForUpdates,
  };
});
